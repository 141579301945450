<template>
  <div>

    <b-tabs vertical content-class="col-12 col-md-9 mt-1 mt-md-0" pills nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left">
      <!-- general tab -->
      <b-tab active>
        <!-- title -->
        <template #title>
          <feather-icon icon="UserIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Informations Génerales</span>
        </template>

        <b-card>
          <!-- media -->
          <b-media no-body>
            <b-media-aside>
              <b-link>
                <b-img ref="previewEl" roundedc :src="$themeConfig.app.remote_server_url +avatar_path" height="80" />
              </b-link>
              <!--/ avatar -->
            </b-media-aside>

            <b-media-body class="mt-75 ml-75">
              <!-- upload button -->
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="mb-75 mr-75"
                @click="$refs.refInputEl.$el.click()">
                Importer Photo
              </b-button>
              <b-form-file ref="refInputEl" v-model="avatarFile" accept=".jpg, .png, .gif" :hidden="true" plain
                @input="onFileChange"
                @change="onFileChange" />

              <!-- <div class="mt-3">Selected file: {{ avatarFile ? avatarFile.name : "" }}</div> -->

              <!-- @input="inputImageRenderer" -->
              <!--/ upload button -->

              <!-- reset -->
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" size="sm"
                class="mb-75 mr-75">
                Annuler
              </b-button>
              <!--/ reset -->
              <b-card-text>Extension autorisées: JPG, GIF ou PNG. Max size of 100kB</b-card-text>
            </b-media-body>
          </b-media>
          <!--/ media -->

          <!-- form -->
          <b-form class="mt-2">
            <b-row>

              <b-col sm="6">
                <b-form-group label="Nom complet" label-for="fullName">
                  <b-form-input v-model="userData.fullName" placeholder="Votre nom complet" name="fullName" />
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group label="Nom d'utilisateur" label-for="username">
                  <b-form-input v-model="userData.username" name="username" placeholder="Votre nom d'utilisateur" />
                </b-form-group>
              </b-col>

              <b-col sm="12">
                <b-form-group label="A propos de moi" label-for="aboutme">
                  <b-form-input v-model="userData.aboutme" name="aboutme" placeholder="Je suis...." />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mr-1"
                  @click.prevent="postUpdateUserData">
                  Sauvegarder
                </b-button>
                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" type="reset" class="mt-2"
                  @click.prevent="resetForm">
                  Annuler
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </b-tab>
      <!--/ general tab -->

      <!-- change password tab -->
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon icon="LockIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Documents Officiels</span>
        </template>

        <!-- <corporation-setting-official v-if="options.official" :official-data="options.official" /> -->
      </b-tab>
      <!--/ change password tab -->

      <!-- info -->
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon icon="InfoIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Autres Informations</span>
        </template>

        <!-- <corporation-setting-information v-if="options.information" :information-data="options.information" /> -->
      </b-tab>

      <!-- social links -->
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon icon="LinkIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Web & Social</span>
        </template>

        <!-- <corporation-setting-social v-if="options.social" :social-data="options.social" /> -->
      </b-tab>

      <!-- notification -->
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon icon="BellIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Notifications</span>
        </template>

        <!-- <corporation-setting-notification v-if="options.notification" :notification-data="options.notification" /> -->
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab,
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
/* import CorporationSettingGeneral from "@/views/administration/corporation/CorporationSettingGeneral.vue";
import CorporationSettingOfficial from "@/views/administration/corporation/CorporationSettingOfficial.vue";
import CorporationSettingInformation from "@/views/administration/corporation/CorporationSettingInformation.vue";
import CorporationSettingSocial from "@/views/administration/corporation/CorporationSettingSocial.vue";
import CorporationSettingNotification from "@/views/administration/corporation/CorporationSettingNotification.vue"; */

import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import { $themeConfig } from "@themeConfig";
import router from "@/router";

export default {
  components: {
    BTabs,
    BTab,
    BFormFile,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    /*   CorporationSettingGeneral,
      CorporationSettingOfficial,
      CorporationSettingInformation,
      CorporationSettingSocial,
      CorporationSettingNotification, */
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      avatarFile: null,
      avatar_path: "", 
    };
  },  
  beforeCreate() {
    // local get
    // this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  },
  async mounted() {
    // get from web server
    await this.getUserDataInfos();
  },
  methods: {
    async onFileChange(e) {
      const file = this.avatarFile;

      let formData = new FormData();
      formData.append("avatar", file);

      myApi
        .post("upload_user_avatar", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status == 200) {
            // preview image (refresh)
            this.avatar_path = response.data.full_url;
            
            // update local data
            this.$props.userData.avatar = this.avatar_path
            localStorage.removeItem("userData");
            localStorage.setItem("userData", JSON.parse(this.$props.userData));
          } else {
            this.errors.push(response.message);
          }
        })
        .catch((error) => { });
    },
    async postUpdateUserData() {
      // for the web app
      let formData = new FormData();
      formData.append("fullName", this.ceo);
      formData.append("phone", this.phone);
      formData.append("aboutme", this.email);
      
      await myApi
        .put("user", formData)
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            this.$swal({
              title: "Fin de l'opération",
              text: response.data.message,
              icon: "success",
              timer: 10000,
              showCancelButton: true,
              confirmButtonText: "Continuer",
              cancelButtonText: "Annuler",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-warning ml-1",
              },
              buttonsStyling: false,
            });
          } else {
            this.$swal({
              title: "Erreur Survenu",
              text: response.data.message,
              icon: "error",
              timer: 10000,
              showCancelButton: false,
              confirmButtonText: "Continuer",
              cancelButtonText: "Annuler",
              customClass: {
                confirmButton: "btn btn-warning",
                cancelButton: "btn btn-outline-error ml-1",
              },
              buttonsStyling: false,
            });
          }
        })
        .catch((error) => {
          this.$swal({
            title: "Erreur Survenu",
            text: error.message,
            icon: "error",
            timer: 10000,
            showCancelButton: false,
            confirmButtonText: "Continuer",
            cancelButtonText: "Annuler",
            customClass: {
              confirmButton: "btn btn-warning",
              cancelButton: "btn btn-outline-error ml-1",
            },
            buttonsStyling: false,
          });
        });
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.userData));
    },
    async getUserDataInfos() { 

      this.$props.userData = JSON.parse(localStorage.getItem("userData"));

      this.avatar_path = this.$props.userData.avatar;

      console.log(this.$props.userData.avatar);
      console.log(this.$props.userData);

      return;

      const final_response = await myApi
        .get(`user-profile`)
        .then((response) => {

          console.log(response)

          var corporation_data = response.data.data;

          const corporationDataCollection = {
            ////////////////////////////////////////
            general: {
              corporation_name: corporation_data.fullName,
              corporation_name_mini: corporation_data.fullName,
              ceo: corporation_data.fullName,
              phone: corporation_data.fullName,
              email: corporation_data.fullName,
              country: corporation_data.fullName,
              state: corporation_data.fullName,
              town: corporation_data.fullName,
              adresse: corporation_data.fullName,
              employee_capacity: corporation_data.fullName,
              state: corporation_data.fullName,
            },
            official: {
              legal: corporation_data.fullName,
              idnat: corporation_data.fullName,
              rccm: corporation_data.fullName,
              impot: corporation_data.fullName,
            },
            information: {
              description: corporation_data.fullName,
              slogan: corporation_data.fullName,
            },
            notification: {
              alert_sale: corporation_data.fullName,
              alert_expense: corporation_data.fullName,
              alert_payment: corporation_data.fullName,

              alert_non_attendance: corporation_data.fullName,
              alert_loss: corporation_data.fullName,
              alert_stock: true, // corporation_data.alert_stock,
              alert_expiration: corporation_data.fullName,

              alert_sms: corporation_data.fullName,
              alert_email: corporation_data.fullName,
              alert_contact: corporation_data.fullName,
              alert_fake_account: corporation_data.fullName,
            },
            social: {
              twitter: corporation_data.fullName,
              facebook: corporation_data.fullName,
              googleplus: corporation_data.fullName,
              linkedin: corporation_data.fullName,
            },
          };
          this.options = corporationDataCollection;
          // console.log(this.options);
        })
        .catch((error) => {
          console.log(error);
        });
      return final_response;
    },
  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null); 

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer, 
      $themeConfig,
    };
  },
};
</script>
